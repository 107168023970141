<template>
  <v-container
    id="login"
    class="fill-height justify-center"
  >
    <v-row class="justify-center">
      <v-slide-y-transition appear>
        <common-trs-card
          light
          class="px-10 py-5 ma-3 d-flex align-center login-card"
        >
          <form
            ref="form"
            style="width: 100%;"
          >
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-card-text
                  v-if="step === PASSWORD_SCREEN"
                  class="text-center pa-0"
                >
                  <v-row
                    no-gutters
                    class="my-3"
                  >
                    <v-col>
                      <common-app-logo />
                    </v-col>
                  </v-row>
                  <validation-observer
                    v-slot="{ valid }"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required: true, email: true }"
                      :name="'Email'"
                    >
                      <common-input-text-field
                        id="id_username"
                        v-model="email"
                        class="mt-8"
                        name="username"
                        :filled="false"
                        :dense="false"
                        placeholder="Email"
                        outlined
                        :error-messages="errors"
                        validate-on-blur
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="'Password'"
                    >
                      <common-input-text-field
                        id="id_password"
                        v-model="password"
                        name="password"
                        :filled="false"
                        :dense="false"
                        type="password"
                        placeholder="Password"
                        :error-messages="errors"
                        outlined
                        validate-on-blur
                        @keydown.enter="go"
                      />
                    </validation-provider>
                    <common-trs-btn
                      large
                      depressed
                      type="primary"
                      class="white--text mb-2"
                      block
                      :disabled="!valid"
                      @click="go"
                    >
                      Login
                    </common-trs-btn>
                  </validation-observer>

                  <router-link
                    class="text-body-1 text-primary mx-auto my-4 font-weight-bold text-center text-decoration-none"
                    :to="{ name: 'ForgetPassword' }"
                  >
                    Forgot Password
                  </router-link>
                  <common-app-login-help-zendesk class="mb-4" />
                  <v-row />
                </v-card-text>
                <v-card-text
                  v-if="step === OTP_SCREEN"
                  class="text-center"
                >
                  <validation-observer
                    v-slot="{ valid }"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="'OTP'"
                    >
                      <common-input-text-field
                        v-model="code"
                        :filled="false"
                        :dense="false"
                        :error-messages="errors"
                        placeholder="Code"
                        @keydown.enter="submitCode"
                      />
                    </validation-provider>
                    <v-row class="mx-6 mt-5">
                      <common-trs-btn
                        large
                        depressed
                        type="primary"
                        class="white--text mb-2"
                        :disabled="!valid"
                        block
                        @click="submitCode"
                      >
                        Confirm
                      </common-trs-btn>
                      <common-trs-btn
                        large
                        text
                        type="tertiary"
                        block
                        class="text-none primary--text"
                        @click="bankVaultLogin"
                      >
                        Didn't get it yet? Resend
                      </common-trs-btn>
                    </v-row>
                  </validation-observer>
                </v-card-text>
              </v-col>
              <v-col
                md="2"
                cols="12"
                class="d-flex justify-center align-center"
              >
                <div class="or-text primary">
                  OR
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-column justify-center align-center"
              >
                <div class="scan-txt mb-3">
                  Scan to Login
                </div>
                <img
                  :src="qrCode"
                  alt=""
                  width="200"
                  height="200"
                >
                <div
                  class="font-weight-light text-subtitle-1 mt-3"
                >
                  Secured by
                  <a
                    :href="`https://www.bankvault.com/`"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="secondary--text"
                    style="text-decoration:none;"
                  >
                    BankVault
                  </a>
                </div>
              </v-col>
            </v-row>
          </form>
        </common-trs-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>
<script>
  import { api } from '@/api'
  const PASSWORD_SCREEN = 'password_screen'
  const OTP_SCREEN = 'otp_screen'

  // import { api } from '@/api'
  export default {
    name: 'PagesLogin',

    components: {
      // PagesBtn: () => import('./components/Btn'),
    },

    data: () => ({
      email: '',
      password: '',
      code: '',
      step: PASSWORD_SCREEN,
      PASSWORD_SCREEN,
      OTP_SCREEN,
      sessionData: null,
      qrCode: null,
    }),

    created () {
      this.getSecret()
      this.$store.dispatch('auth/logout')
    },
    mounted () {
      this.$refs.form.submit = () => {
        this.bankVaultLogin()
      }
    },

    methods: {
      goMainPage () {
        if (this.$route.query.redirect) {
          return this.$router.replace(this.$route.query.redirect)
        }
        this.$router.replace({ name: 'Dashboard' })
      },
      async submitCode () {
        try {
          const apiResp = await this.$store.dispatch('auth/submitCode', {
            username: this.email,
            otp: this.code,
          })
          if (apiResp.access) {
            this.$store.dispatch('auth/setAuthInfo', apiResp)
            this.goMainPage()
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Invalid Code.',
          })
        }
      },
      async go () {
        try {
          const apiResp = await this.$store.dispatch('auth/login', {
            username: this.email,
            password: this.password,
          })
          if (apiResp.access) {
            this.$store.dispatch('auth/setAuthInfo', apiResp)
            this.goMainPage()
          } else {
            this.step = OTP_SCREEN
            this.$nextTick(() => {
              this.$refs.otpObserver.reset()
            })
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Email and password does not match.',
          })
        }
      },
      async bankVaultLogin (ev) {
        try {
          const apiResp = await this.$store.dispatch('auth/loginByBankVault', {
            session: this.sessionData.session,
            token: this.sessionData.token,
            secret: this.sessionData.secret,
          })
          if (apiResp.access) {
            this.$store.dispatch('auth/setAuthInfo', apiResp)
            this.goMainPage()
          } else {
            this.step = OTP_SCREEN
            this.$nextTick(() => {
              this.$refs.otpObserver.reset()
            })
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Email and password does not match.',
          })
        }
      },
      async getSecret () {
        try {
          const apiResponse = await api.get('vault-login/session', null, { base: 'admin' })
          this.sessionData = apiResponse.data
          this.qrCode = 'data:image/png;base64, ' + this.sessionData.image
          this.loadScript(this.sessionData.session)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },
      loadScript (sessionId) {
        // eslint-disable-next-line no-undef
        BankVaultApi.init({ session: sessionId, host: 'kbapi-trellis-1.bankvault.com' })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .scan-txt{
    font-size: 20px;
    font-weight: bold;
  }
  .or-text{
    padding: 10px;
    border-radius: 50%;
    color: white;
  }

  .login-card{
    width: 750px !important;
  }

</style>
