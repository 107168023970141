var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height justify-center",attrs:{"id":"login"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('common-trs-card',{staticClass:"px-10 py-5 ma-3 d-flex align-center login-card",attrs:{"light":""}},[_c('form',{ref:"form",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[(_vm.step === _vm.PASSWORD_SCREEN)?_c('v-card-text',{staticClass:"text-center pa-0"},[_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('common-app-logo')],1)],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('validation-provider',{attrs:{"rules":{ required: true, email: true },"name":'Email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{staticClass:"mt-8",attrs:{"id":"id_username","name":"username","filled":false,"dense":false,"placeholder":"Email","outlined":"","error-messages":errors,"validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":'Password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"id":"id_password","name":"password","filled":false,"dense":false,"type":"password","placeholder":"Password","error-messages":errors,"outlined":"","validate-on-blur":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.go($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('common-trs-btn',{staticClass:"white--text mb-2",attrs:{"large":"","depressed":"","type":"primary","block":"","disabled":!valid},on:{"click":_vm.go}},[_vm._v(" Login ")])]}}],null,false,3232785051)}),_c('router-link',{staticClass:"text-body-1 text-primary mx-auto my-4 font-weight-bold text-center text-decoration-none",attrs:{"to":{ name: 'ForgetPassword' }}},[_vm._v(" Forgot Password ")]),_c('common-app-login-help-zendesk',{staticClass:"mb-4"}),_c('v-row')],1):_vm._e(),(_vm.step === _vm.OTP_SCREEN)?_c('v-card-text',{staticClass:"text-center"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('validation-provider',{attrs:{"rules":"required","name":'OTP'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"filled":false,"dense":false,"error-messages":errors,"placeholder":"Code"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitCode($event)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)}),_c('v-row',{staticClass:"mx-6 mt-5"},[_c('common-trs-btn',{staticClass:"white--text mb-2",attrs:{"large":"","depressed":"","type":"primary","disabled":!valid,"block":""},on:{"click":_vm.submitCode}},[_vm._v(" Confirm ")]),_c('common-trs-btn',{staticClass:"text-none primary--text",attrs:{"large":"","text":"","type":"tertiary","block":""},on:{"click":_vm.bankVaultLogin}},[_vm._v(" Didn't get it yet? Resend ")])],1)]}}],null,false,3540161439)})],1):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"md":"2","cols":"12"}},[_c('div',{staticClass:"or-text primary"},[_vm._v(" OR ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"scan-txt mb-3"},[_vm._v(" Scan to Login ")]),_c('img',{attrs:{"src":_vm.qrCode,"alt":"","width":"200","height":"200"}}),_c('div',{staticClass:"font-weight-light text-subtitle-1 mt-3"},[_vm._v(" Secured by "),_c('a',{staticClass:"secondary--text",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://www.bankvault.com/","rel":"noopener noreferrer","target":"_blank"}},[_vm._v(" BankVault ")])])])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }